export default [
  {
    text: "Actions",
    value: "actions",
    align: "left",
    sortable: false,
    width: "10%",
    order: 0,
    hidable: true,
    hidden: false,
  },
  { text: "Name", value: "name", order: 1, hidable: false, hidden: false },
  {
    text: "Hours",
    value: "hours",
    order: 2,
    hidable: true,
    hidden: false,
  },
  {
    text: "Users",
    value: "users",
    order: 3,
    hidable: true,
    hidden: false,
  },
  {
    text: "Notes",
    value: "notes",
    order: 4,
    hidable: true,
    hidden: false,
  },
  {
    text: "Creator",
    value: "createdBy",
    order: 5,
    hidable: true,
    hidden: false,
  },
];
